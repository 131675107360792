import { useState, useEffect } from 'react';

function useAriaDisabled(ref: React.RefObject<HTMLElement>): boolean {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!ref.current) return undefined;

    const element = ref.current;

    const checkAriaDisabled = () => {
      const ariaDisabled = element.getAttribute('aria-disabled');
      setIsDisabled(ariaDisabled === 'true');
    };

    checkAriaDisabled();

    const observer = new MutationObserver(() => {
      checkAriaDisabled();
    });

    observer.observe(element, { attributes: true, attributeFilter: ['aria-disabled'] });

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isDisabled;
}

export default useAriaDisabled;
