import type { SelectValue } from 'antd/lib/select/index';
import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useState, useRef } from 'react';
import { Form, Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import useAriaDisabled from 'hooks/useAriaDisabled';

function SelectWithInput({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;
  const { enumName, ...formInputProps } = formInput;

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <Select formInput={formInputProps} />
    </Form.Item>
  );
}

export default SelectWithInput;

type SelectProps = {
  formInput: FormFieldProps['field']['formInput'];
  id?: string;
  value?: string;
  onChange?: (arg: any) => void;
};

function Select({ formInput, value: selectValue, onChange, ...props }: SelectProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const selectRef = useRef(null);
  const value = searchValue.length > 0 ? searchValue : selectValue;
  const disabled = useAriaDisabled(selectRef);

  function handleOnChange(option: SelectValue) {
    setSearchValue('');

    if (option !== undefined) {
      if (onChange) {
        onChange(option);
      }
    }
  }

  function handleOnSearch(searchQuery: string) {
    if (searchQuery && searchQuery.length > 0) {
      setSearchValue(searchQuery);
    }
  }

  function handleOnBlur() {
    if (searchValue.length > 0 && onChange) {
      onChange(searchValue);
    }
  }

  return (
    <div className={`custom_antd_select_${props?.id || ''}`} ref={selectRef}>
      <AntdSelect
        showSearch
        autoClearSearchValue={false}
        value={value}
        onChange={handleOnChange}
        onSearch={handleOnSearch}
        onBlur={handleOnBlur}
        disabled={disabled}
        {...formInput}
        {...props}
      >
        {formInput.options?.map(({ key, value: optionValue }) => (
          <AntdSelect.Option key={key} value={key}>
            {optionValue}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </div>
  );
}
